<template>
  <div
    class="d-flex flex-column mt-5 main-wrapper"
    style="
      padding-left: 6px !important;
      padding-right: 6px !important;
      height: 100vh;
      overflow-y: scroll;
      background-image: url(''); background-size: cover; padding-bottom: 15rem; background-attachment: fixed;
    "
  >
    <div class="account-container-wrapper d-flex pl-0 mt-2 py-2">
      <div class="col-5 my-auto">
        <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/score97/8.png" style="width: 92px; height: 92px" alt="">
      </div>

      <div class="col-7 p-0">
        <div class="pt-2">
          <div class="d-flex">
            <p class="mb-1" style="width: 100px; color: dark; font-weight: 500;"> 
                        {{ $t('setting.name') }}</p>
            <p class="MuiTypography-root MuiTypography-body1 css-eurmd1 mb-1">: {{$store.state.authUser.name}} </p>
          </div>
          <div class="d-flex">
            <p class="mb-1" style="width: 100px; color: dark; font-weight: 500;">
                        {{ $t('setting.balance') }}</p>
            <p class="MuiTypography-root MuiTypography-body1 css-eurmd1 mb-1">:
              <b>{{ Number($store.state.amount).toLocaleString() }}</b> MMK</p>
          </div>
          <div class="d-flex">
            <p class="mb-1" style="width: 100px; color: dark; font-weight: 500;">
                        {{ $t('setting.phone') }}</p>
            <p class="MuiTypography-root MuiTypography-body1 css-eurmd1 mb-1">: {{ $store.state.authUser.phone }} </p>
          </div>
          <div class="d-flex">
            <p class="mb-1" style="width: 100px; color: dark; font-weight: 500;">
                        {{ $t('setting.withdraw') }}</p>
            <p class="MuiTypography-root MuiTypography-body1 css-eurmd1 mb-1">: {{Number($store.state.totalWithdraw).toLocaleString() }} </p>
          </div>
        </div>
      </div>
  </div>

  <div class="account-container-wrapper d-flex pl-0 mt-2 py-2">
    <div class="col-12 justify-content-between my-auto d-flex">
        <!-- <img src="https://cdn.gold549.com/build/assets/img/theme/profile.png" style="width: 92px; height: 92px" alt=""> -->
        <div class="col-3 text-center pt-1" style="cursor: pointer" @click="$playSound(); $router.push('/deposit')">
          <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/score97/deposit%20(4).png" style="width: 1.7rem; height: 1.7rem" alt="">
          <div class="mx-auto mt-2 text-sm text-nowrap" style="color: dark; font-weight: 500;">
              {{$t('setting.deposit')}}
          </div>
        </div>
        <div class="col-3 text-center pt-1" style="cursor: pointer" @click="$playSound(); $router.push('/withdraw')">
          <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/score97/withdraw%20(2).png" style="width: 1.7rem; height: 1.7rem" alt="">
          <div class="mx-auto mt-2 text-sm text-nowrap" style="color: dark; font-weight: 500;">
              {{ $t('setting.withdraw') }}
          </div>
        </div>
        <div class="col-3 text-center pt-1" style="cursor: pointer" @click="$playSound(); $router.push('/history')">
          <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/score97/testing.png" style="width: 1.7rem; height: 1.7rem" alt="">
          <div class="mx-auto mt-2 text-sm text-nowrap" style="color: dark; font-weight: 500;">
              {{ $t('setting.transaction') }}
          </div>
        </div>
        <div class="col-3 text-center pt-1" style="cursor: pointer" @click="$playSound(); $router.push('/betslip-history')">
          <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/score97/report.png" style="width: 1.7rem; height: 1.7rem" alt="">
          <div class="mx-auto mt-2 text-sm text-nowrap" style="color: dark; font-weight: 500;">
              {{ $t('setting.voucher') }}
          </div>
        </div>

      </div>
  </div>

  <div class="col-lg-3 col-12 pl-0 side-nav-bar-custom">
    <nav class="side-nav card my-2" style="border-radius: 10px;">
      <div>
        <div>
          <!-- Nav items -->
          <ul class="navbar-nav-custom">
            <li class="nav-items">
              <div class="nav-link">
                <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/score97/id-card%20(2).png" style="width: 1.7rem; height: 1.7rem; margin-left: -0.5rem; margin-right: 1rem;" alt="">
                <span class="text-dark">
                  {{$t('setting.name') + ' - ' + $store.state.authUser.user_name}}
                </span>
              </div>
            </li>
            <li
              class="nav-items"
              data-toggle="modal"
              data-target="#editUserPassword"
            >
              <div class="nav-link d-flex justify-content-between">
                <div>
                  <!-- <i class="fas fa-key mr-3" style="color: dark; font-weight: 500;"></i> -->
                  <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/score97/reset-password%20(2).png" style="width: 1.7rem; height: 1.7rem; margin-left: -0.5rem; margin-right: 1rem;" alt="">
                  <span class="text-dark">{{ $t('setting.update_password') }}</span>
                </div>
                <div>
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
            </li>
            <li
              class="nav-items"
              @click="() => $router.push('/rule')"
            >
              <div class="nav-link d-flex justify-content-between">
                <div>
                  <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/score97/employment-rules.png" style="width: 1.7rem; height: 1.7rem; margin-left: -0.5rem; margin-right: 1rem;" alt="">
                  <span class="text-dark">{{ $t('setting.rule') }}</span>
                </div>
                <div>
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
            </li>
            <li
              class="nav-items"
            >
              <div class="nav-link d-flex justify-content-between">
                <!-- <div>  -->
                  <div>
                    <!-- <i class="fas fa-music mr-3 pt-1" style="color: dark; font-weight: 500;"></i> -->
                    <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/score97/music%20(1).png" style="width: 1.7rem; height: 1.7rem; margin-left: -0.5rem; margin-right: 0.7rem;" alt="">
                    <span class="mr-3 text-dark p-0">
                      {{$t('setting.sound')}}
                    </span>
                  </div>
                  
                  <div>
                    <van-switch
                      :value="$store.state.music"
                      active-color="dark"
                      inactive-color="#dcdee0"
                      @click="onOffMusic()"
                  />
                  </div>
                  
                <!-- </div> -->
              </div>
            </li>
            <li class="nav-items">
              <div
                class="nav-link d-flex justify-content-between"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div id="navbar-language">
                  <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/score97/language%20(1).png" style="width: 1.7rem; height: 1.7rem; margin-left: -0.5rem; margin-right: 1rem;" alt="">
                  <span class="text-dark">{{ $t('setting.update_language') }}</span>
                </div>

                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbar-language"
                >
                  <a class="dropdown-item" @click="$playSound(); setLangLocalstorage('en')"
                    ><img
                      src="https://cdn.myanmarsoccer.xyz/build/assets/img/icons/common/united-kingdom.png"
                      alt=""
                      class="mr-2"
                      style="width: 20px; height: 20px"
                    />
                    {{$t('en')}}</a
                  >
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" @click="$playSound(); setLangLocalstorage('mm')"
                    ><img
                      src="https://cdn.myanmarsoccer.xyz/build/assets/img/icons/common/myanmar.png"
                      alt=""
                      class="mr-2"
                      style="width: 20px; height: 20px"
                    />{{$t("mm")}}</a
                  >
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" @click="$playSound(); setLangLocalstorage('cn')"
                      ><img
                        src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/china.png"
                        alt=""
                        class="mr-2"
                        style="width: 20px; height: 20px"
                      />{{ $t("cn") }}</a
                    >
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click="$playSound(); setLangLocalstorage('th')"
                      ><img
                        src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/general/thailand.png"
                        alt=""
                        class="mr-2"
                        style="width: 20px; height: 20px"
                      />{{ $t("th") }}</a
                    >
                </div>

                <div>
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
            </li>
          </ul>
          
          <!-- Divider -->
          <hr class="my-3" />
          <!-- Navigation -->
          <ul class="navbar-nav-custom mb-md-3">
            <li class="nav-items">
              <div class="nav-link active active-pro" @click="$playSound(); logout()">
                <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/score97/logout%20(1).png" style="width: 1.7rem; height: 1.7rem; margin-left: -0.5rem; margin-right: 0.7rem;" alt="">
                <span class="text-dark text-danger">{{ $t('setting.logout') }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

    <!-- ==== modal for user password edit ==== -->
    <div
      class="modal fade"
      id="editUserPassword"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal-form"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal- modal-dialog-centered modal-sm"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <div class="card border-0 mb-0">
              <div class="card-body px-lg-5 py-lg-5">
                <div class="form-group">
                  {{$t('setting.old_password')}}
                  <div class="mt-2 input-group">
                    <input class="form-control" v-model="password.old" />
                  </div>
                  <span class="text-danger">{{ errors.old }}</span>
                </div>
                <div class="form-group">
                  {{$t('setting.new_password')}}
                  <div class="mt-2 input-group">
                    <input class="form-control" v-model="password.new" />
                  </div>
                  <span class="text-danger">{{ errors.new }}</span>
                </div>
                <div class="form-group">
                  {{$t('setting.confirm_password')}}
                  <div class="mt-2 input-group">
                    <input class="form-control" v-model="password.confirm" />
                  </div>
                  <span class="text-danger">{{
                    errors.confirm || errors.confirmPassword
                  }}</span>
                </div>
                <div class="text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="$playSound(); changeNewPassword()"
                  >
                    <div v-if="!isConfirm">
                      {{$t('setting.update')}}
                    </div>

                    <Loading
                      color="#ffffff"
                      v-if="isConfirm"
                      size="24"
                      class="px-4 py-0"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast, Dialog, Loading } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { Loading },
  data() {
    return {
      password: {
        user_id: this.$store.state.authUser.id,
      },
      isConfirm: false,
      errors: {},
    };
  },
  methods: {
    ...mapActions(['logoutUser', 'fetchUser']),
    onOffMusic() {
      this.$store.commit('setMusic', !this.$store.state.music);
    },
    setLangLocalstorage(language) {
      localStorage.setItem('language', language);
      this.$store.commit('setLanguage', language);
      this.$i18n.locale = language
    },
    changeNewPassword() {
      this.errors = {};
      if (!this.password.old) {
        this.errors.old =
          this.$store.state.language === 'en'
            ? 'Old password required'
            : 'လျို့ဝှက်နံပါတ်အဟောင်း ထည့်ပါ။';
        return false;
      }
      if (!this.password.new) {
        this.errors.new =
          this.$store.state.language === 'en'
            ? 'New password required'
            : 'လျို့ဝှက်နံပါတ်အသစ် ထည့်ပါ။';
        return false;
      }
      if (!this.password.confirm) {
        this.errors.confirm =
          this.$store.state.language === 'en'
            ? 'Confirm password required'
            : 'အတည်ပြုလျို့ဝှက်နံပါတ် ထည့်ပါ။';
        return false;
      }
      if (this.password.new !== this.password.confirm) {
        this.errors.confirmPassword =
          this.$store.state.language === 'en'
            ? 'New passwords not match'
            : 'လျှို့ဝှက်နံပါတ် မတူညီပါ။';
        return false;
      }
      this.isConfirm = true;
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure?'
            : 'သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မလုပ်တော့ပါ။',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ။',
      })
        .then(() => {
          this.updateUser();
        })
        .catch(() => {});
      this.isConfirm = false;
      return true;
    },
    async updateUser() {
      try {
        const res = await axios.post(
          '/auth/userPasswordChange',
          this.password,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        Toast.success(
          this.$store.state.language === 'en' ? 'Success' : 'အောင်မြင်သည်။'
        );
        this.password = {};
        this.errors = {};
        $('#editUserPassword').modal('hide');
      } catch (error) {
        Toast.fail(
          this.$store.state.language === 'en' ? 'Fail' : 'ကျရှုံးသည်။'
        );
      }
    },
    logout() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure to log out?'
            : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မထွက်တော့ပါ',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          if(this.$route.path === '/home') {
            // this.$router.go();
          } else {
            this.$router.push('/home');
            // this.$router.go();
          }
          Toast.success(
            this.$store.state.language === 'en' ? 'Logged out!' : 'ထွက်ပြီးပြီ'
          );
        })
        .catch(() => {});
    },
  },
  async mounted() {
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
  },
};
</script>
<style scoped>
.css-17h40hp {
    margin: 0px;
    line-height: 1.5;
    font-size: 14px;
    font-family: NotoSansMyanmar;
    font-weight: 700 !important;
}
.error {
  margin-bottom: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  color: red;
  border: 1px solid red;
  border-radius: 8px;
  padding: 0px 15px;
  background: #2c32df;
  font-weight: bold;
}
.account-container-wrapper {
    width: auto;
    height: 200px;
    border-radius: 10px;
    color: black;
    background: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
</style>
