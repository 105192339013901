<template>
  <div class="p-0" style="background: #13131D !important; min-height: 100vh; height: auto; padding-bottom: 10rem;">
    <!-- <img src="/build/assets/img/brand/khit_thit.jpg" alt="" /> -->
    <Sticky class="p-0 m-0">
      <NavBar />
    </Sticky>
  
    <div class="col-lg-7 col-12 mx-auto p-0 d-flex main-image-wrapper">
        <b-carousel
            id="carousel-1"
            :interval="6000"
            controls
            fade
            style="text-shadow: 1px 1px 2px #333; width: 100%; height: 100%; padding: 10px;"
            v-if="!threedData.embedded_link && !threedData.isLive"
        >
            <b-carousel-slide
                img-src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/score97/general/Banner-New-Png.png"
                style=" border-radius: 10px;"
            ></b-carousel-slide>
            <!-- <b-carousel-slide
                img-src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/score97/general/B2.gif"
                style=" border-radius: 10px;"
            ></b-carousel-slide> -->
            <!-- <b-carousel-slide
                img-src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/fbet/banner_3.gif"
                style=" border-radius: 10px;"
            ></b-carousel-slide>
            <b-carousel-slide
                img-src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/fbet/banner_4.gif"
                style=" border-radius: 10px;"
            ></b-carousel-slide> -->
        </b-carousel>
    </div>

    <div style="padding-left: 10px; padding-right: 10px;">
      <NoticeBar
        class="col-lg-12 col-12 mx-auto notice-bar"
        scrollable
        :text="websiteInfo[0]?.noticeText"
        v-if="!threedData.embedded_link && !threedData.isLive"
      />
    </div>

    <div class="col-12 account-container-wrapper d-flex py-1 my-2">
      <div class="col-lg-6 col-5 my-auto">
        <img src="https://yy24gld.sgp1.cdn.digitaloceanspaces.com/score97/8.png" style="width: 92px; height: 92px" alt="">
      </div>

      <div class="col-lg-6 col-7 p-0">
        <div class="pt-2">
          <div class="d-flex">
            <p class="css-17h40hp mb-1" style="width: 85px;"> 
                        {{ $t('setting.name') }}</p>
            <p class="css-eurmd1 mb-1" style="color: #ffffff; font-weight: 400;">: {{$store.state.authUser.name}} </p>
          </div>
          <div class="d-flex">
            <p class="css-17h40hp mb-1" style="width: 85px">
                        {{ $t('setting.balance') }}</p>
            <p class="css-eurmd1 mb-1" style="color: #ffffff; font-weight: 400;">:
              <b>{{ Number($store.state.amount).toLocaleString() }}</b>
            {{ $store.state.language === 'mm' ? 'ကျပ်' : 'MMK' }} </p>
          </div>
          <div class="d-flex">
            <p class="css-17h40hp mb-1" style="width: 85px">
                        {{ $t('setting.phone') }}</p>
            <p class="css-eurmd1 mb-1" style="color: #ffffff; font-weight: 400;">: {{ $store.state.authUser.phone }} </p>
          </div>
          <div class="d-flex">
            <p class="css-17h40hp mb-1" style="width: 85px">
                        {{ $t('setting.withdraw') }}</p>
            <p class="css-eurmd1 mb-1" style="color: #ffffff; font-weight: 400;">: {{ Number($store.state.totalWithdraw).toLocaleString() }} {{ $store.state.language === 'mm' ? 'ကျပ်' : 'MMK' }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="justify-content-center cards-container-mobile">
      <div
        class="col-xl-6 col-6 home-cards pr-2 pt-2"
        @click="redirectRoute('body-fb')"
      >
            <img
              src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/score97/general/B1.gif"
              alt=""
              style="width: 100%; height: auto; border-radius: 5px;"
            />
      </div>

      <div
        class="col-xl-6 col-6 home-cards pl-2 pt-2"
        @click="redirectRoute('maung-fb')"
      >
        <img
          src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/score97/general/B2.gif"
          alt=""
          style="width: 100%; height: auto; border-radius: 5px;"
        />
      </div>

      <div
        class="col-xl-6 col-6 home-cards pr-2 py-3"
        @click="redirectRoute('betslip-history')"
      >
          <img
            src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/score97/general/B3.gif"
            alt=""
            style="width: 100%; height: auto; border-radius: 5px;"
          />
      </div>

      <div
        class="col-xl-6 col-6 home-cards pl-2 py-3"
        @click="redirectRoute('history')"
      >
        <img
          src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/score97/general/B4.gif"
          alt=""
          style="width: 100%; height: auto; border-radius: 5px;"
        />
      </div>


      <div
        class="col-xl-6 col-6 home-cards pr-2 pb-3"
        @click="redirectRoute('deposit')"
      >
        <img
          src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/score97/general/B5.gif"
          alt=""
          style="width: 100%; height: auto; border-radius: 5px;"
        />
      </div>

      <div
        class="col-xl-6 col-6 home-cards pl-2 pb-3"
        @click="redirectRoute('withdraw')"
      >
        <img
          src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/score97/general/B6.gif"
          alt=""
          style="width: 100%; height: auto; border-radius: 5px;"
        />
      </div>

      <div class="col-xl-6 col-6 home-cards pr-2" @click="redirectRoute('setting')">
        <img
          src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/score97/general/B8.gif"
          alt=""
          style="width: 100%; height: auto; border-radius: 5px;"
        />
      </div>

      <div
        class="col-xl-6 col-6 home-cards pl-2"
        @click="redirectRoute('Live')"
      >
        <img
          src="https://space-tech.sgp1.cdn.digitaloceanspaces.com/score97/general/B7.gif"
          alt=""
          style="width: 100%; height: auto; border-radius: 5px;"
        />
      </div>
      <!-- <div class="col-12 d-flex justify-content-end px-0">
        <div
          class="col-3 home-cards pl-1 mr-0 mt-4 mb-3"
          @click="logout()"
        >
          <div
            class="card d-flex flex-row justify-content-center align-items-center mb-2 py-2 px-2"
          >
              <img
                src="https://cdn.gold549.com/build/assets/img/theme/logout.png"
                alt=""
                style="width: 20px; height: 20px"
              />
            <div>
              <p class="css-38745a pl-2" style="color: #000000; font-weight: 400;">Log out</p>
            </div>
          </div>
        </div>
      </div> -->

    </div>
  </div>
</template>
<script>
import { NoticeBar, Dialog, Toast, Sticky } from 'vant';
import { mapActions } from 'vuex';

export default {
  components: { NoticeBar, Sticky },
  data() {
    return {
      twoDdata: {},
      twoDHistories: {},
      threeDHistories: {},
      threedData: {},
      websiteInfo: {},
    };
  },
  methods: {
    ...mapActions(['logoutUser', 'fetchUser']),
    logout() {
      Dialog.confirm({
        title:
          this.$store.state.language === 'en'
            ? 'Are you sure to log out?'
            : 'ထွက်မှာ သေချာပါသလား?',
        cancelButtonText:
          this.$store.state.language === 'en' ? 'Cancel' : 'မထွက်တော့ပါ',
        confirmButtonText:
          this.$store.state.language === 'en' ? 'Sure' : 'သေချာပြီ',
      })
        .then(async () => {
          await this.logoutUser();
          this.$router.push('/auth/login');
          Toast.success(
            this.$store.state.language === 'en' ? 'Logged out!' : 'ထွက်ပြီးပြီ'
          );
        })
        .catch(() => {});
    },
    async redirectRoute(path) {
      try {
        if (path === 'tip') {
          const text =
            this.$store.state.language === 'en'
              ? 'This feature is not support yet.'
              : 'ယခုအချိန်တွင် အသုံးပြုလို့မရနိုင်သေးပါ။';
          Dialog.alert({
            message: text,
            confirmButtonText:
              this.$store.state.language === 'en' ? 'Okay' : 'Okay',
          }).then(() => {
            // on close
          });
        } else {
          this.$router.push(path);
        }
      } catch (error) {
        console.log(error);
      }
    },
    threedDrawDateFormat(date) {
      return moment(date).format('ll');
    },
    async fetchTwoDdata() {
      try {
        const res = await axios.get('/2d/data');
        this.isLive = res.data.data[0].isLive;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        this.websiteInfo = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchThreedDdata() {
      try {
        const res = await axios.get('/3d/data');
        this.threedData = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTwoThreeDHistories() {
      try {
        const twoDres = await axios.get('/twod_history');
        const threeDres = await axios.get('/threed_history');
        this.twoDHistories = twoDres.data.data;
        this.threeDHistories = threeDres.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    console.log('home')
    const authUser = await this.fetchUser();
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    console.log(authUser)
    this.fetchWebsiteData();
    this.fetchUser();
    // this.fetchTwoDdata();
    // this.fetchThreedDdata();
    // this.fetchTwoThreeDHistories();
  },
};
</script>
<style scoped>
.css-38745a {
    margin: 0px;
    line-height: 1.5;
    font-size: 12px;
    /* font-family: NotoSansMyanmar; */
    font-weight: 500;
}
.css-17h40hp {
    margin: 0px;
    line-height: 1.5;
    font-size: 14px;
    /* font-family: NotoSansMyanmar; */
    font-weight: 700 !important;
}
.card {
  cursor: pointer;
  border-radius: 10px !important;
}
.main-image-wrapper img {
  width: 100%;
}
.video-container {
  height: 500px;
  width: 100%;
}
.notice-bar {
  margin-top: -40px;
  z-index: 1;
  font-size: 20px;
  color: #000000;
  font-weight: 500;
  background: rgba(255, 242, 0, 0.7);
}
.twod-threed-cards {
  height: 200px;
}
.live-number {
  font-weight: bold;
  font-size: 25px;
}
.live-result {
  font-weight: bolder;
  font-size: 60px;
  animation-name: live-number-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
.not-live-text {
  font-weight: bold;
  font-size: 24px;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
}
.card-body {
  padding: 0px !important;
}
.cards-container-mobile {
  display: none;
}
@keyframes live-number-animation {
  from {
    color: white;
  }
  to {
    color: red;
  }
}

/* @media (max-width: 768px) { */
  .middle-container {
    /* padding-top: 0.1rem !important; */
  }
  hr {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .video-container {
    height: 300px;
    width: 100%;
  }
  .main-wrapper {
    padding: 7px !important;
  }
  .notice-bar {
    margin-top: 0px;
    height: 25px;
    z-index: 1;
    font-size: 0.9rem;
    color: #FFFFFF;
    background: #1F212E;
    border-radius: 5px;
  }
  .live-number {
    font-weight: bold;
    font-size: 20px;
  }
  .live-result {
    font-weight: bolder;
    font-size: 37px;
  }

  .cards-container {
    display: none;
  }
  .cards-container-mobile {
    padding: 0px !important;
    margin-top: 5px !important;
    display: flex;
    flex-wrap: wrap;
  }
  .home-cards {
    padding-left: 10px;
    padding-right: 10px;
  }
  .card-body {
    padding: 0px !important;
  }
  .not-live-text {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .account-container-wrapper {
    width: auto;
    height: auto;
    border-radius: 10px;
    padding-left: 4px;
    padding-right: 4px;
    margin: 10px;
    color: #BBBDC3;
    background: #1F212E;
    /* border: 1px solid #595f70; */
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  }
  .top-wrapper,
  .bottom-wrapper {
    padding: 10px;
    width: 100%;
    display: flex;
  }
  .top-right,
  .bottom-right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: end;
  }
  .top-left,
  .bottom-left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: start;
  }
/* } */
</style>
