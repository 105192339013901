<template>
  <div class="container-fluid p-2" style="scrollbar-width: none; -ms-overflow-style: none; background: black; height: 100vh; overflow-y: scroll; background-size: cover; padding-bottom: 15rem; background-attachment: fixed;">
        <div style="height: 150vh;">
          <div class="col-12 px-1 mb-5 pb-5">
            <div class="box__bg card" v-for="(promotion, proIndex) in promotions" :key="proIndex">
              <img :src="promotion.image" style="border-top-right-radius: 0.6rem; border-top-left-radius: 0.6rem;" alt="">
              <div class="px-3 py-2 pt-3 d-flex justify-content-between" style="background: #1A1A1A; border-bottom-right-radius: 0.6rem;  border-bottom-left-radius: 0.6rem;">
                <h3 class="animate-charcter my-auto">
                  <picture class="mr-2">
                    <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.webp" type="image/webp">
                    <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.gif" alt="💸" width="25" height="25">
                  </picture>
                  {{ promotion.title }}
                  <picture class="ml-2">
                    <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.webp" type="image/webp">
                    <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.gif" alt="💸" width="25" height="25">
                  </picture>
                </h3>
                <button class="detail-button col-3" @click="$playSound(); promotionIndex(proIndex)">{{$t('detail')}}</button>
              </div>

              <van-overlay :show="selectedPromotion === proIndex" @click="selectedPromotion = null">
                  <div class="wrapper col-12 d-flex h-100vh">
                    <div class="col-11 card mx-auto my-auto py-4">
                      <h2> {{ promotion.title }}</h2>
                      <span class="text-dark"> {{ promotion.description }}</span>
                    </div>
                  </div>
              </van-overlay>

            </div>
          </div>
        </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      websiteInfo: [],
      isLoading: false,
      selectedPromotion: null,
      promotions: [
        {
          title: '👀 မန်ဘာသစ် ၁၀၀% ဘောနပ် ',
          description: '5,000 သွင်း 5,000ဘောနပ် 10,000 သွင်း 10,000 ဘောနပ် ⛔ ဘောနပ်ယူပါက မောင်းတမျိုးတည်းသာကစားခွင့်ရှိသည်။ ⛔ မောင်းတစ်မောင်း လျှင် ဘော်ဒီ ၅ သင်း (ဘော်ဒီအမျိုးအစားသီးသန့်ရွေးချယ်ကာ) လောင်းရမည်။ (မှတ်ချက်- ဂိုးပေါ်/ဂိုးအောက်၊ စုံ/မ များအားရွေးချယ်ခွင့်မရှိ) ( 🚫ယူနစ်အားလုံးသည်စလစ်တစ်ခုတည်းသာလောင်းရပါမည် ခွဲလောင်းခွင့်မရှိပါ ) ⛔ သွင်းငွေနှင့်ဘောနပ်၏ (10)ဆ နိုင်မှအကန့်သတ်မရှိ ထုတ်ယူခွင့်ရှိသည်။ ❌ အထက်တွင်ဖော်ပြထားသည့်စည်းကမ်းချက်များနှင့်အညီ မောင်းမကစားဘဲ ဘော်ဒီကစားထားသည်ကိုတွေ့ရှိပါက အနိုင်ငွေလုံးဝ ထုတ်ပေးမည်မဟုတ်ပါရှင့် ❌။',
          image: 'https://yy24gld.sgp1.cdn.digitaloceanspaces.com/fbet/bonus_1.gif',
        },
        {
          title: '🗓️ နေ့စဉ် ဘောနပ်စ် ၁၀%',
          image: 'https://yy24gld.sgp1.cdn.digitaloceanspaces.com/fbet/bonus_2.gif',
          description: '⛔ သွင်းငွေ ၅,၀၀၀ ကျပ်မှစတင်ပြီးရယူနိုင်ပါသည်။ ⛔ သွင်းငွေအား အနည်းဆုံးဘော်ဒီ ၂ ပွဲကစား‌ပေးရမည်ဖြစ်ပြီး ဘောနပ်ရငွေအား ၄ သင်းမောင်းတတွဲသာ အတိအကျ လောင်းပေးရပါမည်။ (ဘောနပ်ငွေကို ခွဲလောင်း၍မရပါ) ⛔ သွင်းငွေ + ဘောနပ်၏ အနိမ့်ဆုံးတစ်ဆအနိုင်ရရှိအောင် ကစားပီးမှသာအနိုင် ငွေအားထုတ်‌ပေးပါမယ်ရှင့်။ ဥပမာ သွင်းငွေ ၅၀၀၀ + ဘောနပ်စ် ၅၀၀ အနိုင်ရရှိငွေ ၅,၅၀၀။ သွင်းငွေ + ဘောနပ်စ် + အနိုင်ရရှိငွေ = စုစုပေါင်း ၁၁,၀၀၀။ ❌ ဘောနပ်စည်းကမ်းမလိုက်နာပါ အနိုင်ငွေလုံးဝ ထုတ်ပေးမည်မဟုတ်ပါရှင့်။ ❌',
        },
        {
          title: '⛓️‍💥 ကျိုးမောင်းဘောနပ်',
          image: 'https://yy24gld.sgp1.cdn.digitaloceanspaces.com/fbet/bonus_3.gif',
          description: '⛔ ၄ သင်းနိုင် ၁ သင်းကျိုးမောင်းများ မှ စတင်ခံစားခွင့်ရှိသည်။ ⛔ ကျိုးမောင်းလောင်းကြေးငွေရဲ့ နှစ်ဆကို ဘောနပ်စ်ပြန်လည်ပေးအပ်ပါမည်။ ⛔ ဘောနပ်ငွေကိုဘော်ဒီတစ်သင်းကစားကာ အနိုင်ရရှိခဲ့လျှင် အနိုင်ငွေထုတ်ယူနိုင်ပါသည်။ ❌ အနိုင်ငွေဆက်လက်ကစားထားပါက အလျော်အစားမလုပ်ပါရှင့်။ ❌',
        },
        {
          title: '🤝 သူငယ်ချင်းမိတ်ဆက် ဘောနပ် 🤝',
          image: 'https://yy24gld.sgp1.cdn.digitaloceanspaces.com/fbet/bonus_4.gif',
          description: '⛔ သူငယ်ချင်းသွင်းငွေ ရဲ့ 15% ခံစားခွင့်ရှိပါသည်။ ⛔ Bonus ရရှိငွေ 3000 အထက်ရရှိပါကအထက်ပါ‌ဘောနပ်စ်အားဘော် ဒီတစ်ပွဲ လောင်းပေးရပါမည်။ ⛔ Bonus ရရှိငွေ 3000အောက်ရရှိပါက  3 သင်း‌မောင်းတတွဲ လောင်းပေးရပါမည်။ (ဘောနပ်ငွေ ခွဲလောင်း၍မရပါ) ⛔ အနိုင်ငွေရရှိပါက ထိုအနိုင်ရရှိသည့်ငွေဖြင့် ဆက်လက်၍ မကစားဘဲ အနိုင်‌ငွေအား ချက်ချင်း ထုတ်ယူပေးပါရှင့်။ ❌ စည်းကမ်းချက်များအတိုင်းမလိုက်နာပါက အကောင့်ထဲရှိယူနစ်များအားပြန်လည်သိမ်းယူသွားပါမည်ရှင့်။ ❌',
        },
        {
          title: '🤔 ခန့်မှန်းဘောနပ်',
          image: 'https://yy24gld.sgp1.cdn.digitaloceanspaces.com/fbet/bonus_5.gif',
          description: '❗️ ပွဲစဉ်များ၏ ဂိုးရလဒ်ခန့်မှန်းရုံဖြင့် ဘောနပ် ၁,၀၀၀ ကျပ် ခံစားခွင့်ရှိသည်။ (မှတ်ချက် - ခန့်မှန်းဖြေဆိုမှုများအား FBet ၏ Facebook Page များနှင့် Telegram Channel များရှိခန့်မှန်းပြိုင်ပွဲ Post များတွင်ဝင်ရောက်ကာ comment ပေးရမည်ဖြစ်ပါတယ်ရှင့်။)❗ ထို့ပြင် ခန့်မှန်းချက်မှန်ကန်ပါက ၂၀,၀၀၀ ကျပ်အား ထပ်မံ၍ ဘောနပ်ခံစားခွင့်ရှိဦးမည်ဖြစ်ပါသည်။ ❗️ ဘောနပ်ငွေအား မောင်း ၅ သင်းအတိအကျလောင်းပေးပြီး အနိုင်ရရှိပါက အနိုင်ငွေထုတ်ယူနိုင်ပါသည်။ ❌ အနိုင်ရရှိငွေအားဆက်လက်ကစားထားပါက အလျော်အစားမလုပ်ပါရှင့်။ ❌',
        },
      ]
    };
  },
  methods: {
    ...mapActions(['fetchUser']),
    promotionIndex(index) {
      this.selectedPromotion = index;
    },
    async fetchWebsiteData() {
      try {
        const res = await axios.get('/website-infos');
        this.websiteInfo = res.data.data[0];
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    // const authUser = await this.fetchUser();
    // if (!authUser) {
    //   this.$router.push('/auth/login');
    // }
    this.fetchWebsiteData();
  },
};
</script>
<style scoped>
.box__bg {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* padding: 1rem; */
  /* height: 400px;
  width: 400px; */
  border: 2px solid #0000;
  border-radius: 5rem;
  background: linear-gradient(#131219, #131219) padding-box, linear-gradient(
        var(--angle),
        #070707,
        #e2ba19
      ) border-box;
  animation: 8s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes bgRotate {
  100% {
    transform: rotate(1turn);
  }
}

.detail-button {
  appearance: none;
  background-color: transparent;
  border: 1px solid #e2ba19;
  text-transform: uppercase;
  border-radius: 14px;
  box-sizing: border-box;
  color: #e2ba19;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 0.9rem;
  font-weight: 450;
  line-height: normal;
  margin: 0;
  /* min-height: 15px; */
  min-width: 0;
  outline: none;
  padding: 1px 5px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.detail-button:disabled {
  pointer-events: none;
}

.detail-button:hover {
  color: black;
  background-color: #e2ba19;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.detail-button:active {
  box-shadow: none;
  color: black;
  transform: translateY(0);
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #B50200 0%,
    #f3e706 29%,
    #B50200 67%,
    #f3e706 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
</style>